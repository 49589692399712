@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Gothic');

html,
body,
#root,
.flex-layout {
  height: 100%;
  overflow: auto;
  max-height: 100%;
}

.flex-layout {
  display: flex;
  flex-direction: column;
  position: relative;
}

.page-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 auto;
  width: 100%;
  position: relative;
  height: 100%;
}

.no-y-scroll {
  max-height: 100%;
  overflow: hidden;
}

.page-header {
  display: flex;
  flex: 0 1 auto;
  width: 100%;
}

.footer-container {
  flex-shrink: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --color-transition: 250ms;
  --font-s: calc(5px + 0.8vmin);
  --font-m: calc(8px + 0.8vmin);
  --font-l: calc(18px + 1vmin);
}

/*
  Light Mode:
    Generic Gradient:
      #96dcee
      #7ee8ea
      #7cf3d6
      #97f9b6
      #c3fc91
      #f9f871
    Switch Palette:
      #96dcee
      #003a49
      #9e4300
      #e6f4f1
    Small Switch Palette:
      #96dcee
      #e1eeea
      #488a7b
  Dark Mode:
    Generic Gradient:
      #6b7abb
      #bb7ec2
      #fa85ae
      #ff9e8c
      #ffc86e
      #f9f871
    Switch Palette:
      #6b7abb
      #002055
      #d1a617
      #e6f4f1
    Small Switch Palette:
      #6b7abb
      #ddeeed
      #398b87
*/

@mixin lightmode() {
  --main-color: #6e2b32;
  --second-color: #69dfff;

  --main-color-transient: #6e2b32;

  --color-clickable: var(--second-color);
  --title-fg-color: var(--main-color);
  --title-bg-color: cornsilk;

  --r: 255;
  --g: 255;
  --b: 255;
  --enter-icon-color: #dcdcdc;
  --success-dark: rgb(87, 134, 86);
  --success: rgb(87, 164, 84);
  --fail: rgb(230, 59, 59);
  --text-color: #111;
  --link-color: #9f0dcd;
  --background-color: #eee;
  --second-background-color: #fff;
  --third-background-color: var(--second-color);
  --container-color: white;
  --border-color: black;
  --nav-color: #8f8f8f;
  --purple: #8254ff;

  --progress-bar-inactive: #ccc;
  --progress-bar-active: var(--third-background-color);
  --progress-bar-inner: var(--background-color);
}

@mixin darkmode() {
  --main-color: #6e2b32;
  --second-color: #69dfff;

  --main-color-transient: #c2adfa;

  --color-clickable: #dca9f8;
  --title-fg-color: var(--main-color);
  --title-bg-color: cornsilk;

  --r: 255;
  --g: 255;
  --b: 255;
  --enter-icon-color: #dcdcdc;
  --success-dark: rgb(120, 175, 118);
  --success: rgb(157, 227, 155);
  --fail: rgb(249, 72, 72);
  --text-color: #fff;
  --link-color: #faf697;
  --background-color: #141414;
  --second-background-color: #6b7abb;
  --third-background-color: var(--background-color);
  --container-color: var(--background-color);
  --border-color: var(--background-color);
  --nav-color: #fff;
  --purple: #c2adfa;

  --progress-bar-inactive: #ccc;
  --progress-bar-active: var(--second-background-color);
  --progress-bar-inner: var(--background-color);
}

body {
  scroll-behavior: smooth;
  height: 100vh;

  @include lightmode();

  &.is_inverted {
    @include darkmode();
  }

  /*
  @media (prefers-color-scheme: dark) {
    @include darkmode();

    &.is_inverted {
      @include lightmode();
    }
  }
  */

  background-color: var(--background-color);
  color: var(--text-color);

  a {
    color: var(--link-color);
  }

  -webkit-transition: background-color var(--color-transition) linear;
  -ms-transition: background-color var(--color-transition) linear;
  transition: background-color var(--color-transition) linear;
  margin: 0;
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

.symbols {
  margin-top: 20px;
  margin-bottom: 20px;
}

.container {
  width: 90%;
  height: 85%;
  background-color: var(--container-color);
  border-radius: 20px;
  box-shadow: 0px 25px 40px #1687d933;
  padding: 20px;
  margin-top: 20px;
}

.scrollable-x {
  /* Hide scrollbar for Chrome, Safari and Opera */
  &-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.preload {
  animation-duration: 0s !important;
  -webkit-animation-duration: 0s !important;
  transition: background-color 0s, opacity 0s, color 0s, width 0s, height 0s,
    padding 0s, margin 0s !important;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  z-index: 10;
}

// Force the scroll bar to be visible at all times
/*
html {
  overflow-y: scroll;
}
*/

.padding-s {
  padding-left: 2px;
  padding-right: 2px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clickable-icon,
.clickable {
  cursor: pointer;
  color: var(--color-clickable);
  fill: var(--color-clickable);
}

.bold {
  font-weight: 600;
}

ul,
li,
span,
p,
a {
  font-size: var(--font-m);
  // padding: 0;
  text-align: justify;
}

ul {
  list-style-position: inside;
  padding-left: 0;
}

.font-s {
  font-size: var(--font-s);

  * {
    font-size: var(--font-s);
  }
}

.font-l {
  font-size: var(--font-l);

  * {
    font-size: var(--font-l);
  }
}

h1 {
  margin-top: 0;
  margin-bottom: 5px;
  word-break: break-word;
  font-size: var(--font-l);
  color: var(--main-color);
}

h2 {
  margin-top: 0;
  margin-bottom: 5px;
  color: var(--main-color);
  font-size: var(--font-m);
}

h3 {
  margin-top: 0;
  margin-bottom: 5px;
  color: var(--main-color);
  font-size: var(--font-m);
}

.card {
  padding: 20px;
  border-radius: 16px;
  background-color: var(--second-background-color);
}

.clickable-border {
  border: 2px solid var(--second-color);
}

.card .flex {
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.primary-color {
  color: var(--main-color);
  font-size: 700;
}

.videowrapper {
  float: none;
  clear: both;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}
.videowrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.tool-tip {
  z-index: 2000;
}

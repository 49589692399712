:root {
    /** sunny side **/
    --blue-background: #C2E9F6;
    --blue-border: #72cce3;
    --blue-color: #96dcee;
    --yellow-background: #fffaa8;
    --yellow-border: #f5eb71;
    /** dark side **/
    --indigo-background: #808fc7;
    --indigo-border: #5d6baa;
    --indigo-color: #6b7abb;
    --gray-border: #e8e8ea;
    --gray-dots: #e8e8ea;
    /** general **/
    --white: #fff;
    --width: 60px;
    --sun-width: 30px;
    --toggle-transition: 150ms;
    --second-toggle-transition: 350ms;
    --third-toggle-transition: 250ms;
}

.toggle--checkbox {
    display: none;
}
.toggle--checkbox:checked {
    /** This will all flip from sun to moon **/
    /** Change the label color **/
}
.toggle--checkbox:checked ~ .background {
    background: var(--indigo-background);
}
.toggle--checkbox:checked + .toggle--label {
    background: var(--indigo-color);
    border-color: var(--indigo-border);
    /** Change the cloud to stars **/
    /** Change the sun into the moon **/
    /** Show the dimples on the moon **/
}
.toggle--checkbox:checked + .toggle--label .toggle--label-background {
    left: calc(0.3*var(--width));
    width: 5px;
}
.toggle--checkbox:checked + .toggle--label .toggle--label-background:before {
    width: 5px;
    height: 5px;
    top: calc(-0.125*var(--width));
}
.toggle--checkbox:checked + .toggle--label .toggle--label-background:after {
    width: 5px;
    height: 5px;
    left: calc(-0.125*var(--width));
    top: calc(0.1*var(--width));
}
.toggle--checkbox:checked + .toggle--label:before {
    background: var(--white);
    border-color: var(--gray-border);
    animation-name: switch;
    animation-duration: var(--second-toggle-transition);
    animation-fill-mode: forwards;
}
.toggle--checkbox:checked + .toggle--label:after {
    transition-delay: var(--second-toggle-transition);
    opacity: 1;
}
.toggle--label {
    /** Placeholder element, starting at blue **/
    width: var(--width);
    height: calc(0.5*var(--width));
    background: var(--blue-color);
    border-radius: calc(0.5*var(--width));
    border: 5px solid var(--blue-border);
    display: flex;
    position: relative;
    transition: all var(--second-toggle-transition) ease-in;
    /** The sun cloud and moon stars **/
    /** Sun/Moon element **/
    /** Gray dots on the moon **/
}
.toggle--label-background {
    width: calc(0.1*var(--width));
    height: 5px;
    border-radius: 5px;
    position: relative;
    background: var(--white);
    left: calc(0.75*var(--width));
    top: calc(0.225*var(--width));
    transition: all var(--toggle-transition) ease-in;
}
.toggle--label-background:before {
    content: "";
    position: absolute;
    top: -5px;
    width: calc(0.2*var(--width));
    height: 5px;
    border-radius: 5px;
    background: var(--white);
    left: calc(-0.1*var(--width));
    transition: all var(--toggle-transition) ease-in;
}
.toggle--label-background:after {
    content: "";
    position: absolute;
    top: 5px;
    width: calc(0.2*var(--width));
    height: 5px;
    border-radius: 5px;
    background: var(--white);
    left: calc(-0.05*var(--width));
    transition: all var(--toggle-transition) ease-in;
}
.toggle--label:before {
    animation-name: reverse;
    animation-duration: var(--second-toggle-transition);
    animation-fill-mode: forwards;
    transition: all var(--second-toggle-transition) ease-in;
    content: "";
    width: calc(0.41*var(--sun-width));
    height: calc(0.41*var(--sun-width));
    border: 5px solid var(--yellow-border);
    top: -2px;
    left: 4px;
    position: absolute;
    border-radius: calc(0.41*var(--sun-width));
    background: var(--yellow-background);
}
.toggle--label:after {
    transition-delay: 0ms;
    transition: all var(--second-toggle-transition) ease-in;
    position: absolute;
    content: "";
    box-shadow: var(--gray-dots) -13px 0 0 2px, var(--gray-dots) -24px 14px 0 -2px;
    left: calc(0.715*var(--width));
    top: calc(0.115*var(--width));
    width: calc(0.05*var(--width));
    height: calc(0.05*var(--width));
    background: transparent;
    border-radius: 50%;
    opacity: 0;
}

@keyframes switch {
    0% {
        left: calc(0.02*var(--width));
    }
    60% {
        left: calc(0.02*var(--width));
        width: calc(0.56*var(--sun-width));
    }
    100% {
        left: calc(0.52*var(--width));
        width: calc(0.41*var(--sun-width));
    }
}
@keyframes reverse {
    0% {
        left: calc(0.52*var(--width));
        width: calc(0.41*var(--sun-width))
    }
    60% {
        left: calc(0.36*var(--width));
        width: calc(0.56*var(--sun-width))
    }
    100% {
        left: 4px;
    }
}
.splash-parent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  margin: auto;
  margin-top: 16px;
  margin-bottom: 16px;
  width: calc(90% - 5vmin);

  h2 {
    display: inline-block;
  }
}

.float-right {
  float: right;
}

.splash-div1 {
  grid-area: 1 / 1 / 2 / 4;
  flex: 1;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-wrapper {
  > * {
    display: inline-block;
  }

  display: inline-block;
}

.splash-div2 {
  grid-area: 2 / 1 / 3 / 2;
  text-decoration: none;
  color: var(--text-color);
}

/*
.highlighted {
  color: var(--second-color);
}
*/

.splash-div3 {
  grid-area: 2 / 2 / 3 / 4;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: var(--text-color);

  &:hover {
    .color-svg {
      svg {
        transform: scale(1.1);

        g {
          fill: var(--second-color);
        }
      }
    }
  }

  .color-svg {
    svg {
      height: calc(40px + 5vmin);
      width: auto;
      transform: scale(1);
      transition: transform 0.2s ease-in;

      g {
        fill: var(--main-color);
        transition: all 0.2s ease-in;
      }
    }
  }
}

.flex-down {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1;
}

.splash-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.splash-div4 {
  grid-area: 3 / 1 / 4 / 4;
  max-width: 100%;
  padding: 0;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  max-height: 100px;

  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  .splash-header {
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;

    .flex-space {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    p,
    a {
      text-align: center;
    }

    a {
      color: var(--text-color);
      word-break: break-word;
    }
  }
}

/*
.selected-firm {
}
*/

@media only screen and (max-width: 520px) {
  .splash-parent {
    display: flex;
    flex-direction: column;
  }

  .flex-space,
  .center-wrapper {
    width: 100%;
  }

  .splash-div4 .splash-header {
    h2 {
      text-align: justify;
    }
  }
}

.icon {
  color: var(--text-color);
}

.parent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  overflow-y: scroll;
  border-radius: 16px;

  padding: 16px;
  padding-left: calc(10vmin);
  padding-right: calc(10vmin);

  height: 100%;
}

@keyframes floatText {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-100%);
  }
}

.profile {
  border-radius: 16px;
  grid-area: 1 / 1 / 3 / 2;

  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  justify-content: center;
  background-color: var(--second-background-color);

  .scrolling-text {
    position: absolute;
    bottom: 0.5em;
    left: 0;
    right: 0;
    height: 2.5em;
    background-color: transparent;
    opacity: 0.9;
    transform: translate3d(0px, 0px, 0px) rotate(-2.036deg);
    white-space: nowrap;

    .scroll-text-1 {
      background-color: var(--main-color);
      color: #eee;
    }

    .scroll-text-2 {
      background-color: var(--second-color);
      color: #eee;
    }

    >* {
      display: inline-block;
      height: 100%;
      white-space: normal;

      vertical-align: middle;
      text-align: center;

      text-transform: uppercase;
      font-family: 'Anton', sans-serif;
      display: inline-block;
      white-space: nowrap;
      animation: floatText 10s infinite linear;

      >* {
        display: table;
        height: 100%;
        border-spacing: 5px;
        margin-left: -5px;

        >* {
          padding-left: 5px;
          padding-right: 5px;

          display: table-cell;
          vertical-align: middle;
        }
      }
    }
  }

  .profile-image {
    position: relative;

    overflow: hidden;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;

    display: flex;
    min-width: 180px;

    flex: 1;
    /*
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    */

    img {
      width: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      border: 0;
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
    }
  }

  .profile-content {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;

    >.content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1 1 auto;
    }
  }
}

.div2 {
  grid-area: 1 / 2 / 2 / 3;
  position: relative;
  overflow: hidden;
  height: calc(330px + 5vmin);
}

.div3 {
  grid-area: 1 / 3 / 3 / 4;
}

.div4 {
  grid-area: 2 / 2 / 3 / 3;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;

  >* {
    white-space: normal;
    word-break: break-all;
  }
}

.div5 {
  padding: 0 !important;
  grid-area: 3 / 3 / 4 / 4;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 50%);

  gap: 0px;
  height: auto;
  min-height: 300px;
  overflow: hidden;

  * {
    filter: grayscale(90%);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  :nth-child(1) {
    grid-row-start: 1;
    grid-column-start: 1;

    grid-row-end: 2;
    grid-column-end: 2;
  }

  :nth-child(2) {
    grid-row-start: 1;
    grid-column-start: 2;

    grid-row-end: 2;
    grid-column-end: 3;
  }

  :nth-child(3) {
    grid-row-start: 2;
    grid-column-start: 1;

    grid-row-end: 3;
    grid-column-end: 2;
  }

  :nth-child(4) {
    grid-row-start: 2;
    grid-column-start: 2;

    grid-row-end: 3;
    grid-column-end: 3;
  }
}

.div6 {
  grid-area: 3 / 1 / 4 / 3;
  padding: 0px !important;
  display: flex;
  flex-direction: row;
}

.padding-40px {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  align-content: center;

  flex-wrap: wrap;
  padding: 40px;
  width: 100%;

  * {
    flex: 1;
  }
}

.counter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.left-image {
  // height: 100%;
  max-height: 100%;
  width: calc(10% + 5vmin);
  object-fit: cover;
  border-radius: 16px 0 0 16px;
  filter: grayscale(90%);
}

html,
body {
  overflow: hidden;
}

.skill-list-wrapper {
  display: block;
  width: 100%;
  position: relative;
  //overflow-x: hidden;
}

.arrow {
  position: absolute;
  margin: auto;
  cursor: pointer;
  overflow: hidden;
  color: #fff;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-weight: 500;

  top: 5px;
  bottom: auto;

  &.left-arrow {
    left: auto;
    right: calc(20px + 3vmin);

    >.left-slider {
      width: 24px;
      height: 24px;
      color: var(--color-clickable);

      &:before {
        content: '<';
      }

      &.disabled {
        color: #ccc;
      }

      &:hover {
        color: #333;
      }
    }
  }

  &.right-arrow {
    left: auto;
    right: calc(3vmin);

    >.right-slider {
      width: 24px;
      height: 24px;
      color: var(--color-clickable);

      &:before {
        content: '>';
      }

      &.disabled {
        color: #ccc;
      }

      &:hover {
        color: #333;
      }
    }
  }
}

.div4 {
  a {
    text-decoration: none;
    color: var(--text-color);
    font-weight: 700;

    >.clickable-icon {
      padding-right: 3px;
      vertical-align: middle;
    }
  }
}

.active-icon-up {
  fill: var(--success);
  transition: all ease 0.15s;
}

.active-icon-down {
  fill: var(--fail);
  transition: all ease 0.15s;
}

.bold-text {
  font-weight: 600;
}

.counter-text {
  margin: 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active-text-up {
  color: var(--success);
}

.active-text-down {
  color: var(--fail);
}

.counter-fixed-size {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.2rem;
}

@media only screen and (max-width: 1045px) {
  .parent {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-x: hidden;
  }

  .div2 {
    height: calc(320px - 10vmin);
    min-height: calc(320px - 10vmin);
  }

  .left-image {
    display: none;
  }
}

@media only screen and (max-width: 420px) {
  .profile {
    flex-direction: column;

    .profile-content {
      padding-top: 0 !important;
    }

    .profile-image {
      width: 150px;
      min-width: auto;
      margin: auto;
      margin-top: 1rem;
      border-radius: 50%;

      >img {
        width: 150px !important;
        height: 150px;
        object-fit: cover !important;
        object-position: top;
      }
    }
  }

  .skill-description {
    height: 2em!important;
  }
  .div2 {
    padding: 0!important;
    h1 {
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  /*
  .left-arrow {
    // right: 68px;
  }
  .right-arrow {
    // right: 34px;
  }
  */
}
@keyframes mouseDownOut {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slidein {
  from {
    margin-left: 60vw;
  }

  to {
    margin-left: 0%;
  }
}

@keyframes imageDisappear {
  0% {
    opacity: 100%;
    height: 100%;
  }

  90% {
    opacity: 100%;
  }

  100% {
    opacity: 0%;
    height: 100%;
  }
}

@keyframes imageAppear {
  0% {
    opacity: 0%;
    height: 0%;
  }

  20% {
    opacity: 0%;
    height: 0%;
  }

  21% {
    opacity: 0%;
    height: 100%;
  }

  100% {
    opacity: 100%;
    height: 100%;
  }
}

:root {
  --nb-project: 3;
}

.project-list {
  //width: 100%; // Do not use ! The element overflows parent's width

  display: flex;

  animation-duration: var(--long-duration);
  animation-name: slidein;
  overflow-x: hidden; // hide x-scrollbar
  overflow-y: hidden;
  //margin-top: 2rem;
  padding-left: calc(
    50vw - 0.5 *
      (min(var(--item-zoom-out-max-width), var(--item-zoom-out-width)))
  );
  padding-right: calc(
    50vw - 0.5 *
      (min(var(--item-zoom-out-max-width), var(--item-zoom-out-width)))
  );

  /*
  &.active {
    overflow-y: scroll;
  }
  */

  > .inner {
    height: 100%;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    gap: var(--gap-passive);
    // transition: all var(--short-duration) ease-in, gap 0;
    transition: all var(--short-duration) ease-in;

    &.active {
      gap: var(--gap-active);
    }

    > .project {
      background-color: var(--second-background-color);

      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);

      display: inline-block;
      white-space: nowrap;
      height: var(--min-height-percentage);
      width: var(--item-zoom-out-width);
      max-width: var(--item-zoom-out-max-width);
      // background-color: var(--second-background-color);
      transition: all var(--short-duration) ease-in;

      position: relative;
      padding: 0;
      margin: 0;

      > .project-background {
        width: 100%;
        height: 100%;
        opacity: 100%;
        animation-name: imageAppear;
        animation-duration: 1s;
        animation-direction: normal;

        background-position: center center;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
      }

      // Inactive animation takes precedence
      // over active animation, which takes precedence
      // over hover animation.
      &:not(.visited) {
        &:not(.active) {
          &:hover,
          &.hover {
            height: var(--hover-height-percentage);
            -webkit-filter: grayscale(0%);
            filter: grayscale(0%);
          }
        }
      }

      &.active {
        -webkit-filter: grayscale(0%);
        filter: grayscale(0%);

        > .project-widgets {
          opacity: 100%;

          transition-property: opacity;
          transition-duration: 0.2s;
          transition-delay: 1.6s;
        }

        > .project-container {
          opacity: 100%;
          overflow-y: auto;
          overflow-y: overlay !important; // doesn't exist on all browsers (i.e, Firefox)

          transition-property: opacity;
          transition-duration: 0.2s;
          transition-delay: 1.6s;
          text-align: justify;
        }

        > .project-background {
          animation-name: imageDisappear;
          animation-duration: 1.6s;
          animation-direction: normal;
          opacity: 0%;
          height: 0%;
        }

        // Remove max-width limit
        max-width: none;

        width: var(--item-zoom-in-width);
        height: var(--max-height-percentage);
      }

      &.inactive {
        transition-property: height, width;
        width: calc(
          min(var(--item-zoom-out-width), var(--item-zoom-out-max-width))
        );
        max-width: none;
        height: var(--min-height-percentage);
      }

      &.visited {
        background-color: rgba(100, 100, 100, 0.3);
        background-blend-mode: multiply;
      }

      > .project-container {
        transition-property: opacity;
        transition-duration: 0.2s;

        max-height: 100%;

        opacity: 0%;
        overflow-y: hidden;
        overflow-x: hidden;

        > .project-header {
          border-radius: 10px;
          padding: 30px;
          margin: 10px;
          margin-top: 80px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          > .title-wrapper {
            padding-bottom: 2px;
            position: relative;
            text-decoration: none;
            text-align: center;
          }

          /*
          >.title-wrapper::before {
            content: "";
            background-image: linear-gradient(to bottom, #ff4790, #ff71b4, #ff94d3, #fdb3ec, #fdd1fe);
            position: absolute;
            bottom: 12px;
            left: 10%;
            height: 12px;
            width: 80%;
            z-index: -1;
          }
          */

          .title {
            color: var(--title-fg-color);
            // background: var(--third-background-color) none repeat scroll 0% 0%;
            line-height: 45px;
            text-decoration: none;
            border-radius: 99em;
            font-weight: 800;
            font-family: 'Montserrat', sans-serif;
            padding: 7px 16px 9px;
            // letter-spacing: 2px;
            white-space: normal !important;
            // background-color: var(--title-bg-color);
          }

          > ul {
            // width: 100%;
            list-style-type: none;
            // margin-right: 20px;

            > li {
              white-space: normal;
              padding-bottom: 8px;
            }
          }
        }

        > .project-body {
          p {
            margin-bottom: 5px;
            margin-top: 0;
          }

          background-color: var(--second-background-color);
          border-radius: 10px;
          padding: 30px;
          margin: 10px;
          white-space: normal;
          display: flex;
          flex-direction: column;

          padding-bottom: 5em;

          iframe,
          image {
            align-self: center;
          }

          img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 50%;
            max-height: 300px;
            object-fit: contain;
            margin-top: 1rem;
            margin-bottom: 1rem;
            transition: all 0.5s ease-in-out;

            /*
            &:hover {
              -webkit-transform: scale(2);
              // Safari and Chrome
              -moz-transform: scale(2);
              // Firefox
              -ms-transform: scale(2);
              // IE 9
              -o-transform: scale(2);
              // Opera
              transform: scale(2);
            }
            */
          }

          a:link {
            text-decoration: none;
          }

          a:visited {
            text-decoration: none;
          }
        }
      }
    }
  }
}

.project-dot-list {
  margin-top: 2rem;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;

  > .project-dot-container {
    height: calc(10px + 1vmin);
    width: calc(10px + 1vmin);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &:hover {
      > .project-dot {
        background-color: var(--progress-bar-active);
        height: 200%;
      }
    }

    > .project-dot {
      height: 100%;
      width: 50%;
      background-color: var(--main-color);
      display: inline-block;
      transition: all 0.6s ease;

      &:active {
        background-color: var(--second-color);
        height: 300%;
      }
    }
  }
}

.error-wrapper {
  width: 300px !important;
  background-color: #dc4c4c;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;

  > .error {
    margin: 2em !important;
    color: #eee !important;
    font-weight: 700;
  }
}

.project-widgets {
  transition-property: opacity;
  transition-duration: 0.2s;

  z-index: 1000;
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 130px;
  /* Need a specific value to work */

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #eee;
  border-radius: 20px;
  opacity: 0%;

  .project-close-event {
    width: 32px;
    height: 32px;
    padding: 1em;
  }

  .clickable-icon {
    transition: fill 0.2s ease;
    fill: #141414;

    &:active {
      fill: var(--success);
    }

    &:hover {
      fill: var(--success-dark);
    }

    &.disabled {
      cursor: auto;
      fill: gray;

      &:active {
        fill: gray;
      }

      &:hover {
        fill: gray;
      }
    }
  }
}

/*
.project-widgets {
  // Events
  opacity: 0%;
  transition: 0s;

  // Position and centering
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 32px;
  height: 32px;

  position: absolute;
  

  // Style
  cursor: pointer;
  font: 14px/36px 'Roboto', sans-serif;
  background-color: #fff;
  color: #e85046;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.26);
  border: 1px solid #fff;
  border-radius: 99em;

  &:active {
    background: rgba(232, 156, 151, 0.24);
    border: -1px solid rgba(232, 156, 151, 0.24);
  }

  &:hover {
    background-color: #e85046;
    color: #fff;
    border: -1px solid rgba(255, 160, 0, 0.12);
  }
}
*/

@media only screen and (max-width: 680px) {
  .project-header {
    margin-top: 80px !important;
  }
}

@media only screen and (max-width: 560px) {
  .project.active {
    width: var(--item-zoom-in-width-phone) !important;
  }

  .project-dot-list {
    margin-top: 10px !important;
  }

  .title {
    padding: 0 !important;
    line-height: normal !important;
  }
}

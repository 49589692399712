@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes slideInNext {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes slideInPrevious {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes slideOutNext {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes slideOutPrevious {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}

.skill-list {
  position: absolute;

  top: 0;
  left: 10%;
  width: 90%;

  display: flex;
  flex-direction: column;

  animation-name: fadeIn;
  animation-duration: 0.8s;

  &.slide-out-next {
    animation-name: fadeOut, slideOutNext;
    opacity: 0;
    margin-left: translateX(-100%);
  }
  &.slide-out-previous {
    animation-name: fadeOut, slideOutPrevious;
    opacity: 0;
    margin-left: translateX(100%);
  }
  &.slide-in-next {
    animation-name: fadeIn, slideInNext;
  }
  &.slide-in-previous {
    animation-name: fadeIn, slideInPrevious;
  }

  > .skill-header {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
  > .skill-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: stretch;
    > .skill {
      filter: grayscale(90%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 0.8em;
      width: 5em;
      > .skill-image {
        > img {
          max-width: calc(18px + 1vmin);
          max-height: calc(18px + 1vmin);
          width: auto;
          height: auto;
          object-fit: cover;
        }
      }
      > .skill-description {
        color: var(--text-color);
        display: flex;
        flex-direction: column;
        text-align: center;
        height: 4em;
        margin-top: -0.7em;
        margin-bottom: 0.5em;
      }
    }
  }
}

:root {
  --link-transition: 200ms;
}

.nav-menu {
  text-align: right;
  margin: 0 auto;
  border-bottom: solid 1px var(--border-color);
  width: 100vw;

  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--container-color);
  -webkit-transition: background-color var(--color-transition) linear;
  -ms-transition: background-color var(--color-transition) linear;
  transition: background-color var(--color-transition) linear;

  p {
    text-align: justify;
    margin: 16px;
    color: #ccc;
    text-transform: uppercase;
    font-weight: 600;
    position: relative;

    &::before {
      content: attr(data-item);
      color: var(--main-color-transient);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 0;
      overflow: hidden;
    }

    &:hover {
      &::before {
        width: 100%;
      }
    }
  }

  .left {
    list-style: none;

    li {
      margin: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;

      a {
        text-decoration: none;
        color: var(--nav-color);
        font-size: calc(8px + 1vmin);
        font-weight: 400;
        position: relative;
        text-transform: uppercase;

        &::before {
          content: attr(data-item);
          transition: var(--link-transition);
          color: var(--main-color-transient);
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          height: 0;
          overflow-y: hidden;
        }

        &:hover {
          &::before {
            height: 100%;
            transition: all var(--link-transition) ease-in-out;
          }
        }
      }
    }
  }

  .right,
  .left {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
}

.banner-title {
  color: var(--main-color);
  display: inline-block;
  font-family: 'League Gothic';
  font-size: 1.7rem;
  font-weight: lighter;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0.5em;
  letter-spacing: 0.2rem;
}

.country-selector {
  min-width: 120px;

  span {
    color: var(--text-color);
  }

  ul {
    background-color: var(--background-color);
  }
}

.menu-bars {
  font-size: 2rem;
  background: none;
  margin-left: calc(10px + 3vmin);
}

.navbar-toggle {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  display: none !important;
}

.navbar {
  display: none;
}

@media only screen and (max-width: 680px) {
  .navbar {
    position: relative;
    background-color: var(--second-background-color);
    border-bottom: dotted 2px var(--border-color);
    height: 80x;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > .right {
      position: absolute;
      right: 0;

      > .clickable > .banner-title {
        font-size: 1rem;
      }
    }
  }

  .navbar-toggle {
    display: flex !important;
  }

  .nav-menu {
    flex-direction: column;
    max-width: 250px; // If we use width instead of max-width, the menu hovers the screen when its width is less than 200px
    height: 100vh;
    justify-content: flex-start;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index: 1000;

    &.active {
      left: 0;
      transition: 350ms;

      .banner-title {
        margin-top: calc(0.5em + 8vh);
      }
    }

    > .left {
      flex-direction: column;
    }

    > .right {
      margin: 0;

      .banner-title {
        margin-inline-end: 0;
      }
    }
  }
}

.modal-wrapper {
    position: absolute;
    inset: 0px;
    background-color: hsla(0, 0%, 0%, 80%);
    z-index: 998; // 999 is the z-index of the tooltip
    height: 100vh;
    width: 100vw;
    max-height: 100%;
    max-width: 100%;
    
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    
}

.modal-content-wrapper {
    z-index: 0;
    opacity: 1;
    height: 90%;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.modal-content {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
}

.modal-widgets {
    --widgets-width: 300px;
    --widgets-height: 24px;

    > * {
        background-color: #141414;
        padding: 2px;
        height: var(--widgets-height);
    }

    > div, > a {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .modal-zoom-level-wrapper {
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        input {
            width: 40px;
            margin-right: 8px;
        }
    }

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;

    width: var(--widgets-width);
    height: var(--widgets-height);
    
    position: absolute;
    bottom: 30px;
    left: 50%;
    margin-left: calc(-1 * var(--widgets-width) / 2);

    line-height: 1.2;
    

    opacity: 1;
    z-index: 998; // 999 is the z-index of the tooltip

    color: white;
    a {
        color: white;
    }
    text-decoration: none;

    .disabled {
        color: rgba(255, 255, 255, 0.582);
    }
    
}
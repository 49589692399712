// Credit: Aphinya Dechalert, https://www.dottedsquirrel.com/circular-progress-css/

:root {
  --loader-size: 40px;
  --loader-size-half: calc(var(--loader-size) / 2);
  --loader-font-size: 8px;
  --loader-inner-size: 90%;
  --progress-duration: 1s;
}

@keyframes counterSlideIn {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

@keyframes counterSlideOut {
  from {
    opacity: 100%;
  }
  to {
    opacity: 0%;
  }
}

.circular {
  height: var(--loader-size);
  width: var(--loader-size);
  position: absolute;
  top: 2rem;
  right: 2rem;
  transform: scale(2);

  animation-name: counterSlideIn;
  animation-duration: 0.5s;

  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  overflow: hidden;

  &.inactive {
    animation-name: counterSlideOut;
    opacity: 0;
    z-index: -1;
  }

  .inner {
    position: absolute;
    z-index: 6;
    top: calc((100% - var(--loader-inner-size)) / 2);
    left: calc((100% - var(--loader-inner-size)) / 2);
    height: var(--loader-inner-size);
    width: var(--loader-inner-size);
    background-color: var(--progress-bar-inner);

    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    overflow: hidden;
  }

  .number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    font-size: var(--loader-font-size);
    font-weight: 500;
    color: var(--text-color);
  }

  .bar {
    position: absolute;
    height: 100%;
    width: 100%;
    background: var(--progress-bar-inactive);
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    clip: rect(
      0px,
      var(--loader-size),
      var(--loader-size),
      var(--loader-size-half)
    );

    .progress {
      position: absolute;
      height: 100%;
      width: 100%;
      border-radius: 100%;
      -moz-border-radius: 100%;
      clip: rect(0px, var(--loader-size-half), var(--loader-size), 0px);
      background: var(--progress-bar-active);
      filter: grayscale(50%);
    }
  }

  .circle {
    .right {
      transform: rotate(180deg);
      z-index: 1;
    }
    .left .progress {
      z-index: 3;
    }
  }
}

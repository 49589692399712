.legal-card {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;

    border-radius: 16px;
    background-color: var(--second-background-color);
    width: calc(100px + 20%);
}

.margin-32px {
    margin-right: 16px;
    margin-left: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.center-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.legal-wrapper {
    overflow-y: auto;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: auto;
}

@media only screen and (max-width: 700px) {
    .card {
        width:auto;
    }
}
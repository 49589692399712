.footer {
    text-align: right;
    border-top: solid 1px var(--border-color);
    width: 100%;
    max-width: 100%;
    gap: calc(5px + 1vmin);

    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--container-color);
    -webkit-transition: background-color var(--color-transition) linear;
    -ms-transition: background-color var(--color-transition) linear;
    transition: background-color var(--color-transition) linear;
    padding-top: calc(5px + 1vmin);
    padding-bottom: calc(5px + 1vmin);

    a {
        text-decoration: none;
        color: var(--nav-color);
        transition: color 0.1s ease-in-out;
        word-break: break-word !important;

        &:visited {
            color: var(--nav-color);
        }

        &:hover {
            color: var(--main-color);
        }

        &:active {
            color: var(--second-color);
        }
    }

    .right {
        display: flex;
        flex-direction: row;
        gap: calc(5px + 1vmin);
        margin-right: calc(5px + 2vmin);
    }

    .left {
        margin-left: calc(5px + 2vmin);
        text-align: left;
    }
}

@media only screen and (max-width: 680px) {
    .footer {
        border-top: dotted 2px var(--border-color);
    }
}
/*** ENTERPRISES BANNER ***/
:root {
    --animation-speed: 15s;
}

$gradientWidth: calc(5px + 2vmin);

$logoHeight: calc(30px + 2vmin);
$cardHeight: calc(50px + 2vmin);
$logoWidth: calc(50px + 6vmin);
$cardWidth: calc(70px + 6vmin);
$nbLogo: 6;
$multiplicator: 3;
$allLogoWidth: calc($cardWidth * $nbLogo * $multiplicator);
$logoOffset: calc(-1 * $cardWidth * $nbLogo);

@mixin white-gradient {
    background: linear-gradient(to right,
            var(--second-background-color) 0%,
            rgba(255, 255, 255, 0) 100%);
}

// Animation
@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX($logoOffset);
    }
}

// Styling
.slider {
    background: var(--second-background-color);
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
    height: $cardHeight;
    // margin: auto;
    overflow: hidden;
    position: relative;
    max-width: 100%;

    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    &::before,
    &::after {
        @include white-gradient;
        content: '';
        height: $cardHeight;
        position: absolute;
        width: $gradientWidth;
        z-index: 2;
    }

    &::after {
        right: 0;
        top: 0;
        transform: rotateZ(180deg);
    }

    &::before {
        left: 0;
        top: 0;
    }

    .slide-track {
        animation: scroll var(--animation-speed) linear infinite;
        display: flex;
        width: $allLogoWidth;
    }

    .slide {
        height: $cardHeight;
        width: $cardWidth;
        display: flex;

        >img {
            max-height: $logoHeight;
            max-width: $logoWidth;
            margin: auto;
            display: block;
            filter: grayscale(90%);
        }
    }
}
@media only screen and (max-width: 420px) {
    :root {
        --animation-speed: 20s;
    }
}